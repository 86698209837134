import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Container, Row, Column } from 'styled-bootstrap-components';

import Layout from '../components/Layout';
import theme from '../styled/theme';

const PageGrid = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
`;

const StyledContainer = styled(Container)`
  @media (max-width: 767px) {
    ${Row} {
      margin-top: 1rem;
      padding: 2rem;
    }
  }
`;

const Content = styled.div`
  word-break: break-word;

  > p:first-child {
    font-size: 1.25rem;
  }

  p:not(:first-child) {
    color: ${theme.colors.gray};
  }

  a {
    color: ${theme.colors.primary};
    font-weight: bold;
  }
`;

const Page = props => {
  const { data } = props;
  const image = data.wordpressPage.featured_media;

  return (
    <Layout location={props.location}>
      <Helmet title={`${data.wordpressPage.title} - Eddy Herrera`} />
      <PageGrid>{image && <Img resolutions={image.localFile.childImageSharp.resolutions} />}</PageGrid>
      <StyledContainer>
        <Row justifyContent={image ? 'flex-end' : 'flex-start'} mt="5rem">
          <Column md={image ? 8 : 12} bg="white" p="2rem">
            <Content dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
          </Column>
        </Row>
      </StyledContainer>
    </Layout>
  );
};

export const query = graphql`
  query PageQuery($slug: String!) {
    wordpressPage(slug: { eq: $slug }) {
      title
      slug
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            resolutions(width: 720, height: 1080) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
  }
`;

export default Page;
